.App {
  text-align: center;
}

html, body, #root {
  height: 100%;
}

a.light,  a.light:visited {
  color: #000000;
}

a.dark, a.dark:visited {
  color: #FFFFFF;
  text-decoration: none;
}

#mainBar a.dark {
  padding-bottom: 4px;
  padding-top: 4px;
}

#mainBar a.dark.active {
  border-bottom-style: solid;
  border-bottom-color: #ffffff;
  border-bottom-width: 1px;
  padding-bottom: 3px;
}
